export function copyProperties(target, ...originals) {
    for (const original of originals) {
        const originalProps = Object.keys(original).concat(Object.getOwnPropertySymbols(original));
        for (const prop of originalProps) {
            const propDescriptor = Object.getOwnPropertyDescriptor(original, prop);
            if (propDescriptor &&
                !Object.prototype.hasOwnProperty.call(target, prop)) {
                Object.defineProperty(target, prop, propDescriptor);
            }
        }
    }
    return target;
}
export function copyFnProperties(target, original) {
    const internalProps = ['name', 'length'];
    try {
        for (const prop of internalProps) {
            const value = original[prop];
            Object.defineProperty(target, prop, { value });
        }
    }
    catch (_e) {
        // Avoid IE error
    }
    return target;
}
